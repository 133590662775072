<template>
  <section id="popInvite" class="pop notice invite" @click.prevent="closePopup">
    <div class="popBody" @click.stop>
      <a class="close" @click.prevent="closePopup"
        ><font-awesome-icon
          icon="fa-solid fa-xmark"
          size="lg"
        ></font-awesome-icon
      ></a>
      <div>
        <div class="icon">
          <div class="border">
            <font-awesome-icon
              icon="fa-solid fa-gift"
              size="2x"
            ></font-awesome-icon>
          </div>
        </div>
        <span class="title">邀請好友加入會員</span>
        <span>獲得更多優惠！</span>
      </div>
      <div class="action">
        <button @click.prevent="copy">
          <span>我的邀請碼 {{ referralCode }}</span>
          <input
            type="hidden"
            id="referral-code"
            :value="referralCode"
            ref="referral-code"
          />
          <span v-show="isCopied" class="note">已複製</span>
          <!-- 點選後才出現 -->
          <font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon>
        </button>
        <button @click="shareWithLINE">
          <font-awesome-icon
            icon="fa-brands fa-line"
            size="2x"
          ></font-awesome-icon>
          <span>Line邀請好友</span>
          <font-awesome-icon
            icon="fa-solid fa-arrow-up-right-from-square"
          ></font-awesome-icon>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'InvitePopup',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      isCopied: false,
    };
  },
  mounted() {
    popInvite.style.display = 'flex';
    // document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    // document.body.style.overflow = 'scroll';
  },
  computed: {
    ...mapState(['user']),
    referralCode() {
      return this.user.user.myReferralCode;
    },
  },
  methods: {
    closePopup() {
      popInvite.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        this.$emit('closePopup');
        popInvite.children[0].style.animationName = 'popup';
      }, 300);
    },
    copy() {
      const textToCopy = this.$refs['referral-code'];
      textToCopy.select();
      navigator.clipboard.writeText(textToCopy.value).then(() => {
        this.isCopied = true;
      });
    },
    generateReferralUrl() {
      return encodeURIComponent(
        `${this.appHost}?referralCode=${this.referralCode}&openExternalBrowser=1`
      );
    },
    shareWithLINE() {
      window.location.href = `https://line.me/R/share?text=${this.generateReferralUrl()}`;
    },
  },
};
</script>
