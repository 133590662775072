<template>
  <section id="popPointRule" class="pop pointRule" @click.prevent="closePopup">
    <div class="popBody" @click.stop>
      <div>
        <div class="top">
          <span>賺取點數</span>
        </div>
        <div class="bottom">
          <span>現場/線上<br />每消費NT${{ purchase.purchase }}</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
            size="lg"
          ></font-awesome-icon>
          <span>送{{ purchase.accrualAmount }}{{ pointName }}</span>
        </div>
      </div>
      <div>
        <div class="top">
          <span>使用點數</span>
        </div>
        <div class="bottom">
          <span>{{ pointsToCurrency.points }}{{ pointName }}</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
            size="lg"
          ></font-awesome-icon>
          <span
            >可折抵NT${{ pointsToCurrency.currency }}<br />現場/線上消費</span
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'PointRulePopup',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
    };
  },
  mounted() {
    popPointRule.style.display = 'flex';
    // document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    // document.body.style.overflow = 'scroll';
  },
  computed: {
    ...mapState('portal', ['portalInfo']),
    pointName() {
      return this.portalInfo.wallet?.points?.name || '點';
    },
    pointsToCurrency() {
      return this.portalInfo.wallet?.points?.settings?.pointsToCurrency || {};
    },
    purchase() {
      return (
        this.portalInfo.wallet?.points?.settings?.loyaltyProgram?.purchase || {}
      );
    },
  },
  methods: {
    closePopup() {
      popPointRule.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        this.$emit('closePopup');
        popPointRule.children[0].style.animationName = 'popup';
      }, 300);
    },
  },
};
</script>
