<template>
  <div class="store v2">
    <Spinner :spin="isLoading" />
    <!-- Main -->
    <section class="main" v-if="portalInfo">
      <!-- Card -->
      <section class="card">
        <div class="top">
          <span class="title">{{ portalInfo.title }}</span>
          <div v-if="portalInfo.logo">
            <img :src="portalInfo.logo" />
          </div>
        </div>
        <!-- 會員驗證 -->
        <VerificationButton v-if="userInfo.phoneVerified === 0" />
        <!-- 選單 -->
        <Slider
          id="menus"
          class="menus"
          :speed="500"
          :autoplay="false"
          :indicators="false"
          :control-btn="menuPages > 1"
          width="100%"
          height="100%"
          v-model="menuSliderIndex"
          :before-previous="checkMovePrev"
          :before-next="checkMoveNext"
          :style="`min-height: ${menus.length > 4 ? '126' : '60'}px`"
        >
          <SliderItem
            v-for="(page, pageIndex) in menuPages"
            :key="'page_' + pageIndex"
            class="menu"
          >
            <!-- 功能每頁八個 -->
            <a
              v-for="(menu, index) in getMenusInPage(pageIndex + 1)"
              :key="'menu_' + index"
              @click.prevent="menu.clickHandler"
            >
              <font-awesome-icon
                :icon="menu.icon"
                size="xl"
              ></font-awesome-icon>
              <div v-if="menu.name === '通知'" class="notice">
                <span>{{ unreadCount }}</span>
              </div>
              <span>{{ menu.name }}</span>
            </a>
          </SliderItem>
          <div slot="loading"></div>
        </Slider>
        <!-- Carousel -->
        <Slider
          v-if="portalInfo.banners && portalInfo.banners.length"
          id="carousel"
          class="carousel"
          :speed="500"
          :interval="5000"
          :control-btn="false"
          width="100%"
          height="180px"
        >
          <SliderItem
            v-for="(banner, index) in portalInfo.banners"
            :key="'banner' + index"
          >
            <a @click="openExternalUrl(banner.externalUrl)"
              ><img :src="banner.imageUrl"
            /></a>
          </SliderItem>
        </Slider>
      </section>
      <!-- Tabs -->
      <section class="tabs">
        <div class="bg"></div>
        <fragment v-if="tabs.length > 1">
          <a
            v-if="hasPointsTab"
            :class="{ active: tab === 'points' }"
            @click="tab = 'points'"
          >
            <img src="@/assets/icon/pointW.svg" />
            <span
              >點數 <span>{{ displayedPointBalance }}</span></span
            >
          </a>
          <a
            v-if="hasTicketsTab"
            :class="{ active: tab === 'tickets' }"
            @click="tab = 'tickets'"
          >
            <img src="@/assets/icon/ticketW.svg" />
            <span
              >優惠券 <span>{{ tickets.length }}</span></span
            >
          </a>
          <a
            v-if="hasStampsTab"
            :class="{ active: tab === 'stamps' }"
            @click="tab = 'stamps'"
          >
            <img src="@/assets/icon/stampW.svg" />
            <span
              >集點卡 <span>{{ stampCards.length }}</span></span
            >
          </a>
        </fragment>
      </section>
      <!-- 點數 -->
      <section class="point" v-if="tab === 'points'">
        <a class="header" @click="goTo('/point-record')">
          <div>
            <div><img src="@/assets/icon/point.svg" /></div>
            <span class="title">{{ pointName }}</span>
            <span>{{ displayedPointBalance }}</span>
          </div>
          <span v-if="displayedPointPeriods"
            >{{ displayedPointPeriods.pointBalance }}點 將於
            {{ tsToDate(displayedPointPeriods.endTs) }}到期</span
          >
        </a>
        <!-- 點數紀錄預覽最多3筆 -->
        <div
          class="list"
          v-for="(transaction, index) in displayedTransactions"
          :key="'transaction' + index"
        >
          <div>
            <span>{{ transaction.note }}</span>
            <span>{{ tsToDatetime(transaction.transactionTs) }}</span>
          </div>
          <span
            >{{ displaySignOfPoint(transaction.entryType)
            }}{{ transaction.pointAmount }}</span
          >
        </div>
        <!-- 按鈕 -->
        <div class="action">
          <a @click="goTo('/point-record')">
            <font-awesome-icon icon="fa-solid fa-list-ul"></font-awesome-icon>
            <span>交易紀錄</span>
          </a>
          <a @click="pointRulePopupOpen = true">
            <font-awesome-icon
              icon="fa-solid fa-circle-info"
            ></font-awesome-icon>
            <span>點數規則</span>
          </a>
        </div>
        <!-- 點數規則popup -->
        <PointRulePopup
          v-if="pointRulePopupOpen"
          @closePopup="pointRulePopupOpen = false"
        />
      </section>
      <!-- 優惠券 -->
      <section class="coupon" v-if="tab === 'tickets'">
        <a class="list" @click="goTo('/tickets')">
          <font-awesome-icon
            icon="fa-solid fa-list-ul"
            size="lg"
          ></font-awesome-icon>
          <span>兌換紀錄</span>
        </a>
        <a v-if="ticketInitialized && !tickets.length" class="coupon">
          <div class="dot left"></div>
          <div class="dot right"></div>
          <span class="none">暫無優惠券</span>
        </a>
        <a
          v-else
          class="coupon"
          v-for="(ticket, index) in tickets"
          :key="'ticket_' + index"
          @click="goTo(`/tickets/${ticket.ticketId}`)"
        >
          <div class="dot left"></div>
          <div class="dot right"></div>
          <div class="content">
            <div>
              <span class="title">{{ ticket.title }}</span>
              <span class="name">{{ ticket.storeName }}</span>
            </div>
            <span class="info" v-html="ticket.description"></span>
            <span class="date"
              >{{ tsToDate(ticket.useStartAt) }} -
              {{ tsToDate(ticket.useExpirationAt) }}</span
            >
          </div>
          <img v-if="ticket.image" :src="ticket.image" />
        </a>
      </section>
      <!-- 集章 -->
      <StampCards
        v-if="tab === 'stamps'"
        :stampCards="stampCards"
        :selectedStampCard="selectedStampCard"
        @setSelectedStampCard="setSelectedStampCard"
        :stamp="stampInfo"
        @updateStamp="getStamp"
      />
    </section>

    <!-- 通知popup -->
    <NotificationPopup
      v-if="notificationPopupOpen"
      @closePopup="notificationPopupOpen = false"
    />
    <!-- 會員條碼popup -->
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
    <!-- 邀請禮popup -->
    <InvitePopup v-if="invitePopupOpen" @closePopup="invitePopupOpen = false" />
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapGetters } from 'vuex';
import { Slider, SliderItem } from 'vue-easy-slider';
import BarcodePopup from '@/components/BarcodePopup.vue';
import NotificationPopup from '@/components/NotificationPopup.vue';
import PointRulePopup from '@/components/PointRulePopup.vue';
import InvitePopup from '@/components/InvitePopup.vue';
import Spinner from '@/components/Spinner.vue';
import VerificationButton from '@/components/VerificationButton.vue';
import StampCards from '@/components/StampCards.vue';

export default {
  name: 'Home',
  components: {
    BarcodePopup,
    NotificationPopup,
    Slider,
    SliderItem,
    Spinner,
    PointRulePopup,
    InvitePopup,
    VerificationButton,
    StampCards,
  },
  beforeRouteEnter(to, from, next) {
    to.meta.from = from;
    next();
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      tab: '',
      transactions: {},
      tickets: [],
      selectedStampCard: {},
      stampInfo: {},
      barcodePopupOpen: false,
      notificationPopupOpen: false,
      invitePopupOpen: false,
      pointRulePopupOpen: false,
      ticketInitialized: false,
      isLoading: false,
      menuSliderIndex: 0,
    };
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapState('portal', ['portalInfo']),
    ...mapGetters({
      userPoints: 'getUserPoints',
      unreadCount: 'noti/unreadCount',
      needUpdateStampCards: 'noti/needUpdateStampCards',
    }),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    displayedPointBalance() {
      return this.userPoints?.pointBalance || 0;
    },
    displayedPointPeriods() {
      return this.userPoints?.pointPeriods
        ?.filter(p => p.endTs * 1000 > Date.now())
        .sort((a, b) => a.endTs - b.endTs)[0];
    },
    displayedTransactions() {
      return this.transactions?.records?.slice(0, 3) || [];
    },
    pointName() {
      return this.portalInfo?.wallet?.points?.name;
    },
    menus() {
      const services = this.portalInfo?.design?.services?.map(service => {
        let clickHandler;
        switch (service.key) {
          case 'barcodes':
            clickHandler = () => (this.barcodePopupOpen = true);
            break;
          case 'stores':
          case 'articles':
          case 'profile':
            clickHandler = () => this.goTo(`/${service.key}`);
            break;
          case 'notifications':
            clickHandler = () => (this.notificationPopupOpen = true);
            break;
          case 'mgm':
            clickHandler = () => (this.invitePopupOpen = true);
            break;
          default:
            clickHandler = () => {
              if (!service.url) return;
              window.open(service.url, '_blank');
            };
        }

        return {
          ...service,
          clickHandler,
        };
      });
      return services || [];
    },
    menuPages() {
      return Math.ceil(this.menus.length / 8);
    },
    tabs() {
      if (!this.portalInfo?.wallet) return [];
      return Object.keys(this.portalInfo.wallet).filter(
        tab => this.portalInfo.wallet[tab]?.enabled
      );
    },
    hasPointsTab() {
      return this.portalInfo?.wallet?.points?.enabled;
    },
    hasTicketsTab() {
      return this.portalInfo?.wallet?.tickets?.enabled;
    },
    hasStampsTab() {
      return this.portalInfo?.wallet?.stamps?.enabled;
    },
    stampCards() {
      return this.portalInfo?.wallet?.stamps?.stampCards || [];
    },
  },
  mounted() {
    this.hideMenusPrevArrow();
  },
  activated() {
    if (this.hasPointsTab) {
      this.getUserPoints().then(() => {
        if (this.userPoints.pointCode) {
          this.getTransactions();
        }
      });
    }

    if (this.hasTicketsTab) {
      this.getTickets();
    }
  },
  deactivated() {
    this.closePopup();
  },
  watch: {
    menuSliderIndex(index) {
      index === 0 ? this.hideMenusPrevArrow() : this.showMenusPrevArrow();
      index === this.menuPages - 1
        ? this.hideMenusNextArrow()
        : this.showMenusNextArrow();
    },
    tabs: {
      immediate: true,
      handler() {
        this.tab = this.tabs.length ? this.tabs[0] : '';
      },
    },
    stampCards: {
      immediate: true,
      handler() {
        this.selectedStampCard = this.stampCards.length
          ? this.stampCards[0]
          : {};
      },
    },
    selectedStampCard: {
      immediate: true,
      handler() {
        this.selectedStampCard.stampCardId && this.getStamp();
      },
    },
    needUpdateStampCards() {
      if (this.needUpdateStampCards) {
        this.getStamp();
        this.$store.commit('noti/setNeedUpdateStampCards', false);
      }
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    checkMovePrev() {
      return this.menuSliderIndex !== 0;
    },
    checkMoveNext() {
      return this.menuSliderIndex !== this.menuPages - 1;
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD HH:mm');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    getMenusInPage(page) {
      return this.menus.slice((page - 1) * 8, page * 8);
    },
    closePopup() {
      this.barcodePopupOpen = false;
      this.notificationPopupOpen = false;
      this.pointRulePopupOpen = false;
      this.invitePopupOpen = false;
    },
    showMenusPrevArrow() {
      const prevArrow = menus?.querySelector('.slider-btn-left');
      if (prevArrow) prevArrow.style.display = 'block';
    },
    hideMenusPrevArrow() {
      const prevArrow = menus?.querySelector('.slider-btn-left');
      if (prevArrow) prevArrow.style.display = 'none';
    },
    showMenusNextArrow() {
      const nextArrow = menus?.querySelector('.slider-btn-right');
      if (nextArrow) nextArrow.style.display = 'block';
    },
    hideMenusNextArrow() {
      const nextArrow = menus?.querySelector('.slider-btn-right');
      if (nextArrow) nextArrow.style.display = 'none';
    },
    displaySignOfPoint(entryType) {
      return entryType == 'C' ? '-' : '';
    },
    openExternalUrl(externalUrl) {
      if (externalUrl) {
        window.open(externalUrl, '_blank');
      }
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };
      return this.$http(config)
        .then(res => {
          this.$store.commit('updateUserPoints', res.data);
        })
        .catch(err => {
          console.log('getUserPoints err:', err);
        });
    },
    callGetStampAPI() {
      let config = {
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/collectors/${this.user.userId}/stamp-cards/${this.selectedStampCard.stampCardId}/collector-status`,
      };
      return this.$http(config);
    },
    getStamp() {
      this.callGetStampAPI()
        .then(res => {
          this.stampInfo = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    callTransactionsAPI() {
      let endTime = Math.floor(Date.now() / 1000);
      let startTime = endTime - 1 * 60 * 60 * 24 * 90; //3個月

      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
        headers: {},
      };
      return this.$http(config);
    },
    getTransactions() {
      this.callTransactionsAPI()
        .then(res => {
          this.transactions = res.data;
          console.log('getTransactionsResData: ', res.data);
        })
        .catch(err => {
          console.log('getTransactions err: ', err);
        });
    },
    callGetTicketsAPI() {
      let url = `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`;
      var config = {
        method: 'get',
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    dummyTickets() {
      let result = [
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022131',
          productId: 1,
          name: '【50嵐】10元折價券（限桃園地區分店）',
          title: '【50嵐】10元折價券（限桃園地區分店）',
          description: '使用此券可折抵任一飲品10元',
          storeId: 7,
          images: [],
          storeName: 'GP健康定食創意料理店家',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761721,
          tickets: [
            {
              ticketId: 6,
              barcodeMessage: 'AAM654003488393',
              status: 'pickedup',
              updatedTs: 1664761722,
            },
            {
              ticketId: 7,
              barcodeMessage: 'AAM654003496576',
              status: 'pickedup',
              updatedTs: 1664761891,
            },
          ],
        },
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022132',
          productId: 2,
          name: '【yoxi】50元乘車金兌換券',
          title: '【yoxi】50元乘車金兌換券',
          description: '可使用此券兌換一組50元乘車金序號',
          storeId: 6,
          images: [],
          storeName: 'Miss Energy',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761891,
          tickets: [
            {
              ticketId: 8,
              barcodeMessage: 'AAMV552R817VZPR',
              status: 'expired',
              updatedTs: 1664761891,
            },
          ],
        },
      ];
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    getTickets() {
      this.callGetTicketsAPI()
        // this.dummyTickets()
        .then(res => {
          console.log('getTicketsResData', res.data);
          this.tickets = res.data
            .reduce((acc, product) => {
              const tickets = product.tickets.map(ticket => {
                const ticketCopy = { ...ticket };
                ticketCopy.title = product.title;
                ticketCopy.description = product.description;
                ticketCopy.storeName = product.storeName;
                ticketCopy.image = product.images[0];
                ticketCopy.useStartAt = product.useStartAt;
                ticketCopy.useExpirationAt = product.useExpirationAt;
                ticketCopy.updatedAt = ticket.updatedTs || ticket.createdTs;
                return ticketCopy;
              });
              return (acc = [...acc, ...tickets]);
            }, [])
            .filter(t => t.status === 'pickedup')
            .sort((a, b) => a.updatedAt - b.updatedAt);
        })
        .catch(err => {
          console.log('getTickets err:', err);
        })
        .finally(() => (this.ticketInitialized = true));
    },
    setSelectedStampCard(val) {
      this.selectedStampCard = val;
    },
  },
};
</script>

<style>
#carousel .slider-indicators {
  bottom: 5px;
}

#carousel .slider-indicator-icon {
  margin: 0 5px;
  border: 1px solid white;
  background-color: white;
  opacity: 1;
  box-shadow: 0px 5px 10px rgba(55, 84, 170, 0.2);
}

#carousel .slider-indicator-active {
  background-color: var(--k1);
}

#carousel {
  border-radius: 10px;
}

#menus .slider-btn {
  background: transparent;
  width: 18px;
  height: 18px;
  top: 50%;
  transform: translateY(-50%);
}
#menus .slider-btn .slider-icon {
  border-left: 2px solid var(--k1);
  border-bottom: 2px solid var(--k1);
  width: 13px;
  height: 13px;
  top: 50%;
}

#menus .slider-item {
  align-items: unset;
}
</style>
